<template>
  <div class="div-add">
    <div class="background">
    </div>
    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('notifWalletSuccessAdd')"
    :headerMsg="$t('Success')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('BacktoWallet')"
    imgSource="notification/success2.svg"
    typeModal="transaction"
    disableBtnNo="true"
    :onHandlerYes="buttonOke"/>


    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="failedMessage"
    :headerMsg="$t('failed')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('TryAgain')"
    imgSource="notification/failed.svg"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    disableBtnNo="true"
    :onHandlerYes="buttonOkeFailed"/>

    <div >
      <v-container>
        <h4 class="h4-title">{{$t('AddWallet')}}</h4>
        <form class="div-template">
          <v-row>
            <v-col cols="12" class="pt-0 pb-0">
              <label class="label-content">{{ $t("selectPaymentMethod") }}</label>
              <v-autocomplete
                :items="items"
                v-model="payment_method"
                item-text="wallet_name"
                item-value="wallet_id"
                required
                outlined
                dense
                :placeholder="$t('selectPaymentMethod')"
              >
                  <template v-slot:selection="data">
                    <v-avatar left>
                      <v-img :src="data.item.wallet_img"></v-img>
                    </v-avatar>
                    <span style="margin-left: 10px;">{{ data.item.wallet_name }}</span>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="data.item.wallet_img">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.wallet_name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <label class="label-content mb-1">{{ $t("WalletNumber") }}</label>
              <v-text-field
                v-model="payment_number"
                name="WalletNumber"
                v-validate="'required|min:4|max:200'"
                :error-messages="errors.first('WalletNumber')"
                :placeholder="$t('e.g.') + ' 213456789'"
                type="number"
                required
                outlined
                dense
                @keypress="$helpers.typeIntegerOnPressInputHandler"
              ></v-text-field>
            </v-col>
          </v-row>

          <div class="footer-spacer"></div>
          <v-footer fixed app width="auto" color="white" elevation="3">
            <v-container class="mt-0 pt-0 mb-0 pb-0">
              <v-row>
                <v-col
                  class=""
                  cols="12"
                  align-self="center"
                >
                  <ac-button 
                    :active="$partnerACL.isShowButton('save')"
                    :name="$t('titleAdd')" 
                    :on-click-handler="clickAddPayment"
                    :is-disabled="isButtonAddDisabled"
                    width="100%"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-footer>

        </form>
      </v-container>
    </div>
    </div>
</template>
<script>
import store from "@/store";
import AcButton from '@/components/AcButton.vue'
import NotificationPopup from "@/components/NotificationPopup.vue";

export default {
  name: 'PaymentMethodAdd',
  components: { NotificationPopup, AcButton },
  data() {
    return {
      disabledBtn: "",
      notifSuccess: false,
      notifFailed: false,
      failedMessage: "",
      payment_method: "",
      payment_number: "",
      e11: "",
      selected: [2],
      items: [{
        wallet_name: 'MOCA',
        wallet_id: 1,
        wallet_img: require('@/assets/moca.png'),
      },
      {
        wallet_name: 'ZALOPAY',
        wallet_id: 2,
        wallet_img: require('@/assets/zalopay.png'),
      },
      {
        wallet_name: 'MOMO',
        wallet_id: 3,
        wallet_img: require('@/assets/momo.png'),
      },
      ],
      existingPaymentMethod: null
    }
  },
  computed: {
    isButtonAddDisabled() {
      return this.payment_number == "" || this.payment_method == "" || this.errors.any();
    }
  },
  watch: {
  },
  created() {
    this.$store
      .dispatch("customer/getPaymentMethod")
      .then((response) => {
        this.existingPaymentMethod = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {
    const payload = {
      partner_id: store.state.auth.userData.partnerID
    }
    this.$store
      .dispatch("customer/getlistPaymentChannel", payload)
      .then((response) => {
        const list = []
        response.data.map((item) => {
          const wallet = {
            wallet_name: item.name,
            wallet_id: item.payment_channel_id,
            wallet_img: item.icon_url
          }
          list.push(wallet)
        })

        this.items = list
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    clickAddPayment() {
      const payload = {
        wallet_id: this.payment_method,
        wallet_number: this.payment_number,
        priority: this.existingPaymentMethod == null ? 1 : 0,
      };
      this.$store
        .dispatch("customer/addPaymentMethod", payload)
        .then((response) => {
          this.notifSuccess = true
          if (response.status) console.log("success");
        })
        .catch((error) => {
          const responseError = { error }
          // const responseErrorData = responseError.error.response.data;

          // if(responseErrorData.message_code == 403){
          //   this.failedMessage = this.$t(responseErrorData.message)
          // }else {
          //   this.failedMessage = this.$t('notifWalletFailedAdd')
          // }
          // this.notifFailed = true
          console.log(responseError);
        });
    },
    buttonOke() {
      this.notifSuccess = false
      // if (this.notifSuccess == false) this.$router.push({ name: "PaymentMethodList" });
      const saveButton = this.$partnerACL.getMetaButton('save');
      if (saveButton) {
        if (saveButton.menu_type == "wsc_menu") {
          this.$router.push({ path: saveButton.redirect_path });
        } else {
          window.location = saveButton.redirect_path;
        }
      } else {
        this.$router.push({ name: "PaymentMethodList" });
      }
    },
    buttonOkeFailed() {
      this.notifFailed = false
    },
    cancelSuccess() {
      this.notifSuccess = false;
    },
  }
}
</script>
<style></style>
<style lang="scss" scoped>
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .div-template {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .div-add {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    bottom: 0;
    padding-top: 3%;
    position: fixed;
  }

  .background {
    width: 100%;
    height: 2%;
    background-color: #f5fdf7;
  }

  .h4-title {
    padding-top: 10px;
    padding-left: 10px;
  }

  .img-add {
    height: auto;
    width: 20px;
  }

  .title-add {
    margin-top: 0.3%;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 4%;
    width: 100%;
    height: 70px;
    text-align: center;
    mix-blend-mode: normal;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .div-template {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .background {
    width: 100%;
    height: 2%;
    background-color: #f5fdf7;
  }

  .div-add {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }

  .h4-title {
    padding-top: 10px;
    padding-left: 10px;
  }

  .img-add {
    width: 40px;
    height: auto;
  }

  .title-add {
    margin-top: 0.3%;
  }

  // .footer{
  //   position: fixed;
  //   left: 0;
  //   bottom: 4%;
  //   width: 100%;
  //   height: 70px;
  //   text-align: center;
  //   mix-blend-mode: normal;
  // }

}</style>